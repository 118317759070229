import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { ReactComponent as Section1SVG } from '../Assets/Section1.svg';

import "./Section1.css";
export default function Section1() {
    return (
        <Grid className='section1outline'>            
             <Section1SVG className="background-svg" />
             {/* <Typography variant="body1" gutterBottom className='bodytextStyle' color="secondary">
                At Story Loft, we believe in the power of a good story – to inform, influence, and to initiate transformations.
                <br/>We believe the right story can touch anything: from heartstrings to minds to purse-strings.
            </Typography>  */}
        </Grid>
    )
}