import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import "./Section3.css";
import { ReactComponent as Section3SVG } from '../Assets/Section3.svg';
import { useNavigate } from 'react-router-dom';

export default function Section3() {
    const navigate = useNavigate();
    useEffect(() => {
        const characterText = document.getElementById('characters');
        if (characterText) {
            characterText.addEventListener('click', () => {
                navigate('/characters')
            });
        }

        // Cleanup the event listener on component unmount
        return () => {
            if (characterText) {
                characterText.removeEventListener('click', () => {
                });
            }
        };
    }, []);
    return (
        <Grid className='section3outline'>
            <Section3SVG />
        </Grid>
    )
}