import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { ReactComponent as SectionPerformanceAndFineArt } from '../Assets/performanceAndFineArt.svg';
import "./prefAndFineArt.css";
function setImageHref(imageId, url) {
    const imageElement = document.getElementById(imageId);
    if (imageElement) {
        imageElement.setAttributeNS("http://www.w3.org/1999/xlink", "xlink:href", url);
    } else {
        console.error(`Element with id ${imageId} not found.`);
    }
}

export default function PerformanceAndFineArt({ pref_section_id, fineArt_section_id }) {
    var prefImages = [];
    var prefStartingIndex = 0;
    var fineArtImages = [];
    var fineArtStartingIndex = 0;
    var isUseEffectcalled = false;
    useEffect(() => {
        if (!isUseEffectcalled) {
            isUseEffectcalled = true;
            let requestOptions = {
                method: "GET",
                redirect: "follow"
            };
            //get Performance Image order
            fetch("https://thestoryloft.in/api/orders/" + pref_section_id, requestOptions)
                .then((response) => response.json())
                .then((orderResult) => {
                    console.log(orderResult[0].orderList, "orderList");
                    fetch(`https://thestoryloft.in/api/images?section_id=${pref_section_id}&refresh=` + Number(Math.random()).toFixed(1), requestOptions)
                        .then((response) => response.json())
                        .then(async (result) => {
                            console.log(result);
                            prefImages = result.map(val => {
                                val["image_order"] = orderResult[0].orderList.split(",").indexOf(val.image_id.toString());
                                if (val["image_order"] == -1) val["image_order"] = 10000;
                                return val;
                            }).sort((a, b) => a.image_order - b.image_order);

                            if (prefImages[prefStartingIndex]) {
                                setImageHref("perf_image", prefImages[prefStartingIndex].image_url);
                            }
                        })
                        .catch((error) => console.error(error));
                })
                .catch((error) => console.error(error))
            //get Fine Art Image order
            fetch("https://thestoryloft.in/api/orders/" + fineArt_section_id, requestOptions)
                .then((response) => response.json())
                .then((orderResult) => {
                    console.log(orderResult[0].orderList, "orderList");
                    fetch(`https://thestoryloft.in/api/images?section_id=${fineArt_section_id}&refresh=` + Number(Math.random()).toFixed(1), requestOptions)
                        .then((response) => response.json())
                        .then(async (result) => {
                            console.log(result);
                            fineArtImages = result.map(val => {
                                val["image_order"] = orderResult[0].orderList.split(",").indexOf(val.image_id.toString());
                                if (val["image_order"] == -1) val["image_order"] = 10000;
                                return val;
                            }).sort((a, b) => a.image_order - b.image_order);

                            if (fineArtImages[fineArtStartingIndex]) {
                                setImageHref("fineArt_image", fineArtImages[fineArtStartingIndex].image_url);
                            }
                        })
                        .catch((error) => console.error(error));
                })
                .catch((error) => console.error(error))
        }
    }, []);

    useEffect(() => {
        const leftArrow = document.getElementById('pref_moveleft');
        const rightArrow = document.getElementById('pref_moveright');
        if(leftArrow && prefStartingIndex == 0) leftArrow.style.cursor = "no-drop";
        if (rightArrow && prefStartingIndex+1  == prefImages.length ) rightArrow.style.cursor = "no-drop";
        if (leftArrow) {
            leftArrow.addEventListener('click', () => {
                if (prefStartingIndex > 0) {
                    prefStartingIndex--;
                    if (prefStartingIndex == 0) {
                        leftArrow.style.cursor = "no-drop";
                        rightArrow.style.cursor = "pointer";
                    }
                    setImageHref("perf_image", prefImages[prefStartingIndex].image_url);
                }
            });
        }

        if (rightArrow) {
            rightArrow.addEventListener('click', async () => {
                if (prefStartingIndex  < prefImages.length-1) {
                    prefStartingIndex++;
                    if (prefStartingIndex+1  == prefImages.length) {
                        rightArrow.style.cursor = "no-drop";
                        leftArrow.style.cursor = "pointer";
                    }
                    setImageHref("perf_image", prefImages[prefStartingIndex].image_url);
                }
            });
        }

        // Cleanup the event listener on component unmount
        return () => {
            if (leftArrow) {
                leftArrow.removeEventListener('click', () => {
                });
            }
            if (rightArrow) {
                rightArrow.removeEventListener('click', () => {
                });
            }
        };
    }, []);

    useEffect(() => {
        const leftArrow = document.getElementById('fineArt_moveleft');
        const rightArrow = document.getElementById('fineArt_moveright');
        if(leftArrow && fineArtStartingIndex == 0) leftArrow.style.cursor = "no-drop";
        if (rightArrow && fineArtStartingIndex+1  == fineArtImages.length ) rightArrow.style.cursor = "no-drop";
        if (leftArrow) {
            leftArrow.addEventListener('click', () => {
                if (fineArtStartingIndex > 0) {
                    fineArtStartingIndex--;
                    if (fineArtStartingIndex == 0) {
                        leftArrow.style.cursor = "no-drop";
                        rightArrow.style.cursor = "pointer";
                    }
                    setImageHref("fineArt_image", fineArtImages[fineArtStartingIndex].image_url);
                }
            });
        }

        if (rightArrow) {
            rightArrow.addEventListener('click', async () => {
                if (fineArtStartingIndex  < fineArtImages.length-1) {
                    fineArtStartingIndex++;
                    if (fineArtStartingIndex+1  == fineArtImages.length) {
                        rightArrow.style.cursor = "no-drop";
                        leftArrow.style.cursor = "pointer";
                    }
                    setImageHref("fineArt_image", fineArtImages[fineArtStartingIndex].image_url);
                }
            });
        }

        // Cleanup the event listener on component unmount
        return () => {
            if (leftArrow) {
                leftArrow.removeEventListener('click', () => {
                });
            }
            if (rightArrow) {
                rightArrow.removeEventListener('click', () => {
                });
            }
        };
    }, []);

    return (
        <Grid className='pf-outline'>
            <SectionPerformanceAndFineArt />
        </Grid>
    );
}
