import Grid from '@mui/material/Grid';
import "./Header.css";
import Button from '@mui/material/Button';
import Sidebar from '../Components/sidebar';
import Logo from "../Assets/Logo.svg"
import { useNavigate } from 'react-router-dom';

export default function Header() {
    const navigate = useNavigate();
    return (
        <Grid container className='headerOuter'>
            <Grid container className='headerMain'>
                <Grid item xs={4} className='headerMainLeft'>
                    <Button onClick={() => navigate("/")} style={{ cursor: "pointer",padding:0,background:"transparent",color:"transparent" }}>
                        <img src={Logo} style={{ width: "110px", height: "40px" }} alt="StoryLoft" />
                    </Button>
                </Grid>
                <Grid item xs={8} className='headerMainRight'>
                    {/*<Button variant="text" color="secondary" onClick={()=>navigate("/")}>Home</Button>
                    <Button variant="text" color="secondary">About us</Button>
                    <Button variant="text" color="secondary" style={{marginRight:"15px"}}>Gallery</Button>
                    */}
                    <Sidebar />
                </Grid>
            </Grid>
        </Grid>
    )
}