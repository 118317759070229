import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Header from './Sections/Header';
import Portfolio from './SubPages/portfolio';
import Client from './SubPages/client';
import Testimonial from './SubPages/testimonial';
import "./main.css";
import Section1 from './Sections/Section1';
import Section2 from './Sections/Section2';
import Section3 from './Sections/Section3';
import Section4 from './Sections/Section4';
import Section5 from './Sections/Section5';
import { Route, Routes, useLocation } from 'react-router-dom';
import Characters from "./SubPages/characters";
import LearningAndDevelopment from './SubPages/learningAndDevelopment';
import PerformanceAndFineArt from "./SubPages/prefAndFineArt";
import NewsAndUpdates from './SubPages/newsAndUpdates';
import ScrollToTop from './ScrollToTop'; 
import ClientAndTestimonials from "./SubPages/clientsAndtestimonials";
import BrandCommunications from './SubPages/brandCommunications';

export default function Main() {
    const location = useLocation();
    const isHome = location.pathname === '/';
    const isSeperateOutline =  location.pathname === '/characters';
    return (
            <Grid container className={`${isHome ? 'mainOutline' :isSeperateOutline? 'subpageOutline':""}`} >
                <Header />
                <ScrollToTop/>
                <Routes>
                    <Route path="/" element={<React.Fragment>
                        <Section1 />
                        <Section2 />
                        <Section3 />
                        <Section4 />
                        <Section5 />
                    </React.Fragment>} />
                    <Route path="/portfolio" element={<Portfolio />} />
                    <Route path="/client" element={<ClientAndTestimonials />} />
                    <Route path="/testimonials" element={<ClientAndTestimonials />} />
                    <Route path="/characters" element={<Characters section_id={28} />} />
                    <Route path="/learningAndDevelopment" element={<LearningAndDevelopment section_id={23} />} />
                    <Route path="/performanceAndFineArt" element={<PerformanceAndFineArt pref_section_id={24} fineArt_section_id={25} />} />
                    <Route path="/newsAndUpdate" element={<NewsAndUpdates section_id={26} />} />
                    <Route path="/brandCommunications" element={<BrandCommunications section_id={30} />} />
                </Routes>
            </Grid>
    )
}