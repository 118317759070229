import './App.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Main from './main';
import { HashRouter as Router } from 'react-router-dom';
import { useEffect } from 'react';


const theme = createTheme({
  palette: {
    primary: {
      main: '#00B6B5',
      // light: will be calculated from palette.primary.main,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: '#58595B',
      // light: '#F5EBFF',
      // dark: will be calculated from palette.secondary.main,
      // contrastText: '#47008F',
    },
  },
  typography: {
    fontFamily: [
      "Helvetica"
    ].join(','),
    h4: {
      fontFamily: "HelveticaLT107ExtraBlackCondensed"
    },
    body1: {
      fontSize: "16px"
    },
    p: {
      fontFamily: "Helvetica",
      fontSize: "12px"
    }
  },
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          textTransform: 'inherit',
        },
      },
    },
  }
});

function App() {
  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <Main />
      </ThemeProvider>
    </Router>
  );
}

export default App;
