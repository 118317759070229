import Grid from '@mui/material/Grid';
import { ReactComponent as Section2SVG } from '../Assets/Section2.svg';
import Typography from '@mui/material/Typography';

import "./Section2.css";
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Section2() {
    const navigate = useNavigate();
    useEffect(() => {
        const learningAndDevelopment = document.getElementById('learningAndDevelopment');
        if (learningAndDevelopment) {
            learningAndDevelopment.addEventListener('click', () => {
                navigate('/learningAndDevelopment')
            });
        }

        const performances = document.getElementById('performances');
        if (performances) {
            performances.addEventListener('click', () => {
                navigate('/performanceAndFineArt')
            });
        }

        const brandCommunication = document.getElementById('brandCommunication');
        if (brandCommunication) {
            brandCommunication.addEventListener('click', () => {
                navigate('/brandCommunications')
            });
        }

        return () => {
            if (learningAndDevelopment) {
                learningAndDevelopment.removeEventListener('click', () => {
                });
            }
            if (performances) {
                performances.removeEventListener('click', () => {
                });
            }
            if (brandCommunication) {
                brandCommunication.removeEventListener('click', () => {
                });
            }
        }
    }, [])
    return (
        <Grid className='section2outline'>
            <Section2SVG className="background-svg2" />
            {/* <Grid container className='textContent' spacing={2}>
                <Grid item xs={2} className='innerCOntainer' style={{paddingLeft:"0px"}}>
                    <Typography variant="body1" className='section2Header' color="secondary">
                        Brand Communications
                    </Typography>
                    <Typography variant="p" gutterBottom className='section2Para' color="primary">
                        Complete partnerships from strategy <br />
                        to campaign planning to creative;
                    </Typography>
                </Grid>
                <Grid item xs={2} className='innerCOntainer' style={{paddingLeft:"55px"}}>
                    <Typography variant="body1" className='section2Header' color="secondary">
                        Learning & Development
                    </Typography>
                    <Typography variant="p" gutterBottom className='section2Para' color="primary">
                        Workshops & customized training sessions<br />
                        in business creativity, communication skills,
                    </Typography>
                </Grid>
                <Grid item xs={2} className='innerCOntainer' style={{paddingLeft:"105px"}}>
                    <Typography variant="body1" className='section2Header' color="secondary">
                        Performances
                    </Typography>
                    <Typography variant="p" gutterBottom className='section2Para' color="primary">
                        For entertainment, corporate messaging <br />
                        and training, through storytelling, music
                    </Typography>
                </Grid>
            </Grid> */}
        </Grid>
    )
}