import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { ReactComponent as SectionClient } from '../Assets/clientsAndTestimonials.svg';
import "./clientsAndtestimonials.css";
function setImageHref(imageId, url) {
    const imageElement = document.getElementById(imageId);
    if (imageElement) {
        imageElement.setAttributeNS("http://www.w3.org/1999/xlink", "xlink:href", url);
    } else {
        console.error(`Element with id ${imageId} not found.`);
    }
}

function renderTestimonial(message) {
    // Maximum width for each line
    const maxWidth = window.innerWidth > 1000 ? window.innerWidth - (window.innerWidth * (15 / 100)) : window.innerWidth;
    console.log(maxWidth,"maxWidth")
    // Function to create tspan elements
    function createTspanElement(text, className, x, y) {
        const tspan = document.createElementNS("http://www.w3.org/2000/svg", "tspan");
        tspan.setAttribute("x", x);
        tspan.setAttribute("y", y);
        if (className) {
            tspan.setAttribute("class", className);
        }
        tspan.textContent = text;
        return tspan;
    }

    // Get the SVG text element
    const svgTextElement = document.getElementById("dynamic-text");

    // Create tspans and append to the text element
    let currentY = 0;
    message.forEach((msg, idx, arr) => {
        const lines = msg.text.split('\n');
        lines.forEach((lineText, lineIndex) => {
            const words = lineText.split(' ');
            let currentX = idx == arr.length - 1 ? (maxWidth / 2) + 50 : 20;
            let line = '';
            words.forEach((word, index) => {
                const testLine = line + word + ' ';
                const tspan = createTspanElement(testLine, msg.class, currentX, currentY);
                svgTextElement.appendChild(tspan);
                const testWidth = tspan.getComputedTextLength();
                svgTextElement.removeChild(tspan);

                if (testWidth > maxWidth && line !== '') {
                    const finalTspan = createTspanElement(line, msg.class, currentX, currentY);
                    svgTextElement.appendChild(finalTspan);
                    line = word + ' ';
                    currentY += msg.offsetY || 40; // Adjust line spacing as needed inbetween lines
                } else {
                    line = testLine;
                }
            });
            const finalTspan = createTspanElement(line, msg.class, currentX, currentY);
            svgTextElement.appendChild(finalTspan);
            currentY += msg.offsetY || 80; // Adjust line spacing as needed , inbetween message and authr name

            // If the line contains '\n', add additional offset for the next line
            if (lineIndex < lines.length - 1) {
                currentY -= 40;
            }
        });
    });
}

const ClientAndTestimonials = () =>{
    var images = [];
    var startingIndex = 0;
    var testimonials = [];
    var startingTestimonialIndex = 0;
    var isUseEffectcalled = false;
    useEffect(() => {
        if (!isUseEffectcalled) {
            isUseEffectcalled = true;
            let requestOptions = {
                method: "GET",
                redirect: "follow"
            };
            //get image order
            fetch("https://thestoryloft.in/api/orders/21", requestOptions)
                .then((response) => response.json())
                .then((orderResult) => {
                    console.log(orderResult[0].orderList, "orderList");
                    fetch("https://thestoryloft.in/api/images?section_id=21&refresh=" + Number(Math.random()).toFixed(1), requestOptions)
                        .then((response) => response.json())
                        .then(async (result) => {
                            console.log(result);
                            images = result.map(val => {
                                val["image_order"] = orderResult[0].orderList.split(",").indexOf(val.image_id.toString());
                                if (val["image_order"] == -1) val["image_order"] = 10000;
                                return val;
                            }).sort((a, b) => a.image_order - b.image_order);

                            for (let i = 0; i < (images.length < 5 ? images.length : 5); i++) {
                                if (images[i]) {
                                    if (i <= 3) {
                                        setImageHref("client_image" + (i + 1), images[i].image_url);
                                    }
                                }
                            }
                        })
                        .catch((error) => console.error(error));
                })
                .catch((error) => console.error(error))
            
            //get testimonial order
            fetch("https://thestoryloft.in/api/orders/22", requestOptions)
                .then((response) => response.json())
                .then((orderResult) => {
                    console.log(orderResult[0].orderList, "orderList");
                    fetch("https://thestoryloft.in/api/testimonial?refresh=" + Number(Math.random()).toFixed(1), requestOptions)
                        .then((response) => response.json())
                        .then(async (result) => {
                            console.log(result);
                            testimonials = result.map(val => {
                                val["testimonial_order"] = orderResult[0].orderList.split(",").indexOf(val.testimonial_id.toString());
                                if (val["testimonial_order"] == -1) val["testimonial_order"] = 10000;
                                return val;
                            }).sort((a, b) => a.testimonial_order - b.testimonial_order);

                            renderTestimonial([
                                { class: "cls-10", text: testimonials[0].message },
                                { class: "cls-29", text: testimonials[0].message_by } 
                            ])
                        })
                        .catch((error) => console.error(error));
                })
                .catch((error) => console.error(error))
        }
    }, []);

    useEffect(() => {
        const leftArrow = document.getElementById('testimonial_moveleft');
        const rightArrow = document.getElementById('testimonial_moveright');
        if(leftArrow && startingTestimonialIndex == 0) leftArrow.style.cursor = "no-drop";
        if (rightArrow && startingTestimonialIndex+1  == testimonials.length ) rightArrow.style.cursor = "no-drop";
        if (leftArrow) {
            leftArrow.addEventListener('click', () => {
                if (startingTestimonialIndex > 0) {
                    startingTestimonialIndex--;
                    if (startingTestimonialIndex == 0) {
                        leftArrow.style.cursor = "no-drop";
                        rightArrow.style.cursor = "pointer";
                    }
                    document.getElementById("dynamic-text").innerHTML = null;
                    renderTestimonial([
                        { class: "cls-10", text: testimonials[startingTestimonialIndex].message },
                        { class: "cls-29", text: testimonials[startingTestimonialIndex].message_by } 
                    ])
                }
            });
        }

        if (rightArrow) {
            rightArrow.addEventListener('click', async () => {
                if (startingTestimonialIndex  < testimonials.length-1) {
                    startingTestimonialIndex++;
                    if (startingTestimonialIndex+1  == testimonials.length) {
                        rightArrow.style.cursor = "no-drop";
                        leftArrow.style.cursor = "pointer";
                    }
                    console.log(testimonials,startingTestimonialIndex);
                    document.getElementById("dynamic-text").innerHTML = null;
                    renderTestimonial([
                        { class: "cls-10", text: testimonials[startingTestimonialIndex].message },
                        { class: "cls-29", text: testimonials[startingTestimonialIndex].message_by } 
                    ])
                }
            });
        }

        // Cleanup the event listener on component unmount
        return () => {
            if (leftArrow) {
                leftArrow.removeEventListener('click', () => {
                });
            }
            if (rightArrow) {
                rightArrow.removeEventListener('click', () => {
                });
            }
        };
    }, []);
    return (
        <Grid className='ct-outline'>
            <SectionClient  />
        </Grid>
    )
}

export default ClientAndTestimonials;